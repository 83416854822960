<template>
	<div class="back">
		<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
			<el-form-item label="分类名称" prop="name ">
				<el-input  v-model="ruleForm.name " ></el-input>
			</el-form-item>
			<el-form-item label="上级分类" prop="topclass">
			    <el-select v-model="ruleForm.topclass" placeholder="请选择分类">
					<el-option label="区域一" value="shanghai"></el-option>
					<el-option label="区域二" value="beijing"></el-option>
			    </el-select>
			</el-form-item>
			<el-form-item label="" prop="topclass">
			    不选择分类默认为顶级分类
			</el-form-item>
			<el-form-item label="数量单位" prop="numbitem">
				<el-input  v-model="ruleForm.numbitem" ></el-input>
			</el-form-item>
			<el-form-item label="排序" prop="paixu">
				<el-input  v-model="ruleForm.paixu" ></el-input>
			</el-form-item>
			<el-form-item label="是否显示" prop="aaaa">
			    <el-radio-group v-model="ruleForm.aaaa">
			      <el-radio label="是"></el-radio>
			      <el-radio label="否"></el-radio>
			    </el-radio-group>
			</el-form-item>
			<el-form-item label="筛选属性" prop="Attrclass">
				<template v-for="(item,index) in ruleForm.Attrclass">
					<el-select v-model="ruleForm.Attrclass[index]" class="balck" placeholder="请选择属性类型">
						<el-option label="区域一" value="shanghai"></el-option>
						<el-option label="区域二" value="beijing"></el-option>
					</el-select>
					<el-button type="primary" @click="addarr()" size="small">增加</el-button>
					<el-button v-if="index" type="primary" @click="delarr(index)" size="small">删除</el-button>
				</template>
			</el-form-item>
			<el-form-item label="关键词" prop="bbbb">
				<el-input  v-model="ruleForm.bbbb" ></el-input>
			</el-form-item>
			<el-form-item label="分类描述" prop="bbbb">
				<el-input  v-model="ruleForm.bbbb" type="textarea"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as API_goods from '@/api/goods'
	import * as API_goodsCategory from '@/api/goodsCategory'
	import { UE } from '@/components'
	import { RegExp } from '@/../ui-utils'
	import Sortable from 'sortablejs'
	export default {
		name: 'goodsPublish',
		components: {
			UE
		},
		data() {
			return {
				ruleForm: {
					name :'',
					topclass:'',
					Attrclass:[''],
					numbitem:'',
					paixu:'',
					aaaa:'',
					bbbb:''
				},
				rules: {
					name : [
					     { required: true, message: '请输入分类名称', trigger: 'change' }
					],
				}
			};
		},
		mounted() {

		},
		methods: {
			submitForm(formName) {
			    this.$refs[formName].validate((valid) => {
					if (valid) {
						API_goodsCategory.addgoodsCategory(this.ruleForm).then(res=>{

						})
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
			        }
			    });
			},
			addarr(){
				this.ruleForm.Attrclass.push('')
			},
			delarr(v){
				this.ruleForm.Attrclass.splice(v,1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	/*选择商品品类*/
	.demo-ruleForm {
		width: 800px;
		margin: 0 auto;
		.el-input {
			width: 30%;
		}
		.balck{
			margin-right:100px ;
		}
	}
	.back{
		background-color: #FFFFFF;
		min-height: 100%;
		padding-top: 50px;
		padding-bottom: 200px;
	}
</style>
<style scoped>

</style>
